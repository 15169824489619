<template>
  <div class="auth-page with-bg">
    <public-header :button="{url: '/login', text: $t('auth.sign_in')}"/>
    <div class="auth-page__content">
      <div class="container">
        <h2 class="auth-page__title">{{$t('auth.signup_title')}}</h2>
        <div class="box mg-t-30 mg-b-30">
          <div class="title-bold center-text mg-b-20">{{$t('auth.sign_up')}}</div>
          <form @submit.prevent="register" class="flex justify-center mg-t-15">
            <div class="w-400">
              <div class="form-element with-symbol mg-b-15">
                <div class="form-symbol">
                  <i class="fas fa-envelope"/>
                </div>
                <input type="email" autocomplete="email" required v-model="form.email" :placeholder="$t('auth.email')" class="form-control"/>
              </div>
              <div class="form-element with-icon with-symbol mg-b-15">
                <div class="form-symbol">
                  <i class="fas fa-lock"/>
                </div>
                <input :type="password ? 'password' : 'text'" autocomplete="new-password" required v-model="form.password" :placeholder="$t('auth.password')" class="form-control"/>
                <img src="/images/icons/eye.png" @click="togglePass" class="form-icon"/>
              </div>
              <div class="form-element with-icon with-symbol mg-b-15">
                <div class="form-symbol">
                  <i class="fas fa-lock"/>
                </div>
                <input :type="password ? 'password' : 'text'" autocomplete="new-password" required v-model="form.password2" :placeholder="$t('auth.confirm_pass')" class="form-control"/>
                <img src="/images/icons/eye.png" @click="togglePass" class="form-icon"/>
              </div>
              <div class="mg-t-20 mg-b-30">
                <div class="title-red mg-b-10">{{$t('auth.are_you_owner')}}</div>
                <checkbox :checked="isOwner" @change="isOwner = !isOwner" id="keepme" >{{$t('auth.i_am_owner')}}</checkbox>
              </div>
              <div class="flex justify-center mg-b-30">
                <button type="submit" class="btn w-175" :class="{ 'btn--red': isOwner }" :disabled="!isOwner">{{$t('auth.sign_up')}}</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <register-success ref="registerSuccess"/>
    <loading v-if="isLoading"/>
  </div>
</template>

<script>
import Checkbox from '@/components/checkbox/checkbox'
import { Auth } from '@/api/api'
import RegisterSuccess from '@/components/registerSuccess/registerSuccess'
import Loading from '@/components/loading/Loading'
import publicHeader from '@/components/publicHeader/publicHeader'
export default {
  name: 'Register',
  components: { Loading, RegisterSuccess, Checkbox, publicHeader },
  data () {
    return {
      password: true,
      isLoading: false,
      isOwner: false,
      form: {
        email: '',
        password: '',
        password2: ''
      }
    }
  },
  methods: {
    togglePass () {
      this.password = !this.password
    },
    async register () {
      if (this.form.password !== this.form.password2) {
        this.$toast.error(this.$t('auth.pass_no_match'))

        return
      }

      this.isLoading = true

      try {
        await Auth.register(this.form.email, this.form.password)

        this.$refs.registerSuccess.toggle()
      } catch (e) {
        if (e.response.status === 422) {
          this.$toast.error(this.$t('auth.already_registered'))
        } else {
          this.$toast.error(this.$t('auth.register_fail'))
        }
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import "style";
</style>
