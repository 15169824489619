<template>
  <vue-final-modal
    v-model="showModal"
    classes="modal-container"
    content-class="modal-content"
  >
    <div class="modal-close" @click="toggle">
      <i class="fas fa-times"/>
    </div>
    <div class="w-400 flex direction-column align-center pd-20">
      <img src="/images/modal3.png"/>
      <div class="title red pd-t-20 pd-b-20">{{$t('auth.thank_you')}}</div>
      <p class="paragraph">{{$t('auth.signup_txt1')}}</p>
      <p class="paragraph">{{$t('auth.signup_txt2')}}</p>
    </div>
    <div class="flex align-center justify-center mg-t-10">
      <button class="btn btn--red-outline mg-r-10 w-100" @click="toggle">{{$t('global.ok')}}</button>
<!--      <router-link to="/login" class="btn btn&#45;&#45;red w-100" @click="toggle">{{$t('auth.sign_in')}}</router-link>-->
    </div>
  </vue-final-modal>
</template>

<script>
export default {
  name: 'RegisterSuccess',
  components: { },
  props: [
    'status'
  ],
  data () {
    return {
      showModal: false
    }
  },
  created () {
  },
  methods: {
    toggle () {
      this.showModal = !this.showModal
    },
    signIn () {

    }
  }
}
</script>

<style scoped lang="scss">
.title {
  font-size: 25px;
  font-weight: 500;
}

.paragraph {
  text-align: center;
  color: #444;
  font-weight: 300;
  margin: 0;
}
</style>
